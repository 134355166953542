export const environment = {
    production: false,
    version: "GENESIS-DEV-0.0.0",
    keycloak: {
        authority: 'https://isafegreen.timix.cl',
        redirectUri: 'https://isafegreen.timix.cl',
        postLogoutRedirectUri: 'https://isafegreen.timix.cl',
        realm: 'genesis',
        clientId: 'genesis-front',
    },
    urlConfiguracion: 'https://isafegreen.timix.cl/configuracion-backend',
    urlNotificacion: 'https://isafegreen.timix.cl/notificacion-backend',
    urlSocket: 'https://isafegreen.timix.cl/notificacion-backend/stomp',
    urlOperacion: 'https://isafegreen.timix.cl/operacion-backend',

};
